import { useApolloClient } from '@apollo/client';
import { Icon, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { colors } from '@vgrid/colors';
import { FC, useCallback } from 'react';

import { DOCS_TOKEN } from '~/lib/graphql/queries/authentication';
import { useLocalisation } from '~/lib/hooks/localisation';

interface DocsTokenResponse {
  docs: {
    token: string;
  };
}

const DOCS_URL = __DEV__ ? 'http://localhost:3000' : 'https://docs.vgrid.io';

/**
 *
 */
export const DocsMenuItem: FC = () => {
  const client = useApolloClient();
  const localisation = useLocalisation();

  const openDocs = useCallback(() => {
    // Try and get a jwt token from the client
    client
      .query<DocsTokenResponse>({
        fetchPolicy: 'network-only',
        query: DOCS_TOKEN
      })
      .then((res) => {
        if (res.data.docs.token) {
          window.open(
            `${DOCS_URL}/auth/signin?token=${res.data.docs.token}`,
            '_blank'
          );
        } else {
          window.open(DOCS_URL, '_blank');
        }
      })
      .catch(() => {
        window.open(DOCS_URL, '_blank');
      });
  }, [client]);

  if (localisation.get('ui.global.docs.disabled')) {
    return null;
  }

  return (
    <MenuItem
      icon={<Icon icon={IconNames.BOOK} color={colors.emerald.DEFAULT} />}
      onClick={() => openDocs()}
      text="vGRID Docs"
    />
  );
};
