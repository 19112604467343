import { Classes, Icon, MenuDivider, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { colors } from '@vgrid/colors';
import { FC, Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { DocsMenuItem } from '~/components/atoms/DocsMenuItem';
import { VGridLogoFavicon } from '~/components/atoms/VGridLogoFavicon';

import { useCurrentUser } from '../../../../lib/hooks/current-user';
import { useDebug } from '../../../../lib/hooks/debug';
import { useFeatureFlags } from '../../../../lib/hooks/feature-flags';
import { useLocalisation } from '../../../../lib/hooks/localisation';
import { usePermissions } from '../../../../lib/hooks/permissions';
import { useStatusPageUrl } from '../../../../lib/hooks/status-page-url';
import { resourceTypeUIData } from '../../../../lib/localisation/resource-type-ui-data-map';
import { LinkMenuItem } from '../../../atoms/LinkMenuItem';
import { CurrentUserContext } from '../../../context/current-user/current-user-context';
import { RequestFiles } from '../../../pages/vault/Vault/RequestFiles/RequestFiles';
import { HelpDialog } from '../../HelpDialog';

import { NavButton } from './NavButton';
import styles from './Navigation.module.scss';
import { NavigationProvider } from './NavigationContext';
import { NavMenu } from './NavMenu';

/**
 *
 */
function openSentry(): void {
  window.open(
    'https://sentry.io/organizations/vgrid-hq-ltd/releases/' +
      process.env.RELEASE
  );
}

/**
 *
 */
export const Navigation: FC = () => {
  const hasDevMode = useDebug();
  const { email, name } = useCurrentUser();
  const localisation = useLocalisation();
  const permissions = usePermissions();
  const [vaultRequestFilesOpen, setVaultRequestFilesOpen] = useState(false);
  const [isHelpOpen, setHelpOpen] = useState<boolean>(false);

  const { currentUser, logout } = useContext(CurrentUserContext);
  const { statusPageUrl } = useStatusPageUrl();
  const hasFeatures = Object.keys(useFeatureFlags().features).length > 0;

  return (
    <div className={styles.navigation}>
      <NavigationProvider>
        {permissions.hasStreams && (
          <NavButton href="/streams" name="streams" text="Streams" />
        )}

        {permissions.hasAnpr && (
          <NavMenu href="/anpr" name="anpr" text="ANPR">
            {permissions.anpr.alerts && (
              <LinkMenuItem
                href="/anpr/alerts"
                icon={IconNames.UNKNOWN_VEHICLE}
                text="Alerts"
              />
            )}
            {permissions.anpr.insights && (
              <LinkMenuItem
                href="/anpr/insights"
                icon={IconNames.HEATMAP}
                text="Insights"
              />
            )}
            {permissions.anpr.search && (
              <LinkMenuItem
                href="/anpr/search"
                icon={IconNames.SEARCH}
                text="Quick search"
              />
            )}
            {permissions.anpr.realTime && (
              <LinkMenuItem
                href="/anpr/real-time"
                icon={IconNames.LIST_DETAIL_VIEW}
                text="Real-time"
              />
            )}
            {permissions.anpr.export && (
              <LinkMenuItem
                href="/anpr/export"
                icon={IconNames.SEND_TO}
                text="Search &amp; Export"
              />
            )}
            {permissions.anpr.platesOfInterest && (
              <LinkMenuItem
                href="/anpr/plates-of-interest"
                icon={IconNames.KNOWN_VEHICLE}
                ignorePaths={['/anpr/plates-of-interest/create']}
                text="Plates of Interest"
              />
            )}
            {permissions.anpr.platesOfInterestCreate && (
              <LinkMenuItem
                href="/anpr/plates-of-interest/create"
                icon={IconNames.UNKNOWN_VEHICLE}
                path="/anpr/plates-of-interest/create"
                text="Track a plate"
              />
            )}
          </NavMenu>
        )}

        {(permissions.hasVault || permissions.anpr.export) && (
          <NavMenu href="/vault" name="vault" text="Vault">
            <LinkMenuItem
              href="/vault"
              icon={IconNames.BOX}
              path="/vault"
              text="Vault Cases"
            />
            {(permissions.vault.create || permissions.vault.request) && (
              <MenuDivider />
            )}
            {permissions.vault.request && (
              <MenuItem
                icon={IconNames.ENVELOPE}
                onClick={() => setVaultRequestFilesOpen(true)}
                text="Request Files"
              />
            )}
            {permissions.vault.create && (
              <LinkMenuItem
                href="/vault/create"
                icon={IconNames.UPLOAD}
                text="Upload Files"
              />
            )}
          </NavMenu>
        )}

        {permissions.hasAdmin && (
          <NavMenu href="/admin" name="admin" text="Admin">
            {permissions.admin.sessions && (
              <LinkMenuItem
                href="/admin/sessions"
                icon={IconNames.PULSE}
                text="Active user sessions"
              />
            )}
            {permissions.admin.logs && (
              <LinkMenuItem
                href="/admin/logs"
                icon={IconNames.HORIZONTAL_BAR_CHART_DESC}
                text="Activity logs"
              />
            )}
            {(permissions.admin.sessions || permissions.admin.logs) && (
              <MenuDivider />
            )}
            {permissions.admin.anprPoiGroups && (
              <LinkMenuItem
                href="/admin/plates-of-interest-groups"
                icon={resourceTypeUIData.ANPR_POI_GROUP.icon}
                text={resourceTypeUIData.ANPR_POI_GROUP.text}
              />
            )}
            {permissions.admin.streams && (
              <LinkMenuItem
                href="/admin/anpr-streams"
                icon={resourceTypeUIData.ANPR_STREAM.icon}
                text={resourceTypeUIData.ANPR_STREAM.text}
              />
            )}
            {permissions.admin.contacts && (
              <LinkMenuItem
                href="/admin/contacts"
                icon={resourceTypeUIData.CONTACT.icon}
                text={resourceTypeUIData.CONTACT.text}
              />
            )}
            {permissions.admin.edges && (
              <LinkMenuItem
                href="/admin/gateways"
                icon={resourceTypeUIData.EDGE.icon}
                text={resourceTypeUIData.EDGE.text}
              />
            )}
            {permissions.admin.edgeGroups && (
              <LinkMenuItem
                href="/admin/gateway-groups"
                icon={resourceTypeUIData.EDGE_GROUP.icon}
                text={resourceTypeUIData.EDGE_GROUP.text}
              />
            )}
            {permissions.admin.sites && (
              <LinkMenuItem
                href="/admin/sites"
                icon={resourceTypeUIData.SITE.icon}
                text={resourceTypeUIData.SITE.text}
              />
            )}
            {permissions.admin.siteGroups && (
              <LinkMenuItem
                href="/admin/site-groups"
                icon={resourceTypeUIData.SITE_GROUP.icon}
                text={resourceTypeUIData.SITE_GROUP.text}
              />
            )}
            {permissions.admin.streams && (
              <LinkMenuItem
                href="/admin/streams"
                icon={resourceTypeUIData.STREAM.icon}
                text={resourceTypeUIData.STREAM.text}
              />
            )}
            {permissions.admin.users && (
              <LinkMenuItem
                href="/admin/users"
                icon={resourceTypeUIData.USER.icon}
                text={resourceTypeUIData.USER.text}
              />
            )}
            {permissions.admin.userGroups && (
              <LinkMenuItem
                href="/admin/user-groups"
                icon={resourceTypeUIData.USER_GROUP.icon}
                text={resourceTypeUIData.USER_GROUP.text}
              />
            )}
            {permissions.admin.vms && (
              <LinkMenuItem
                href="/admin/vmses"
                icon={resourceTypeUIData.VMS.icon}
                text={resourceTypeUIData.VMS.text}
              />
            )}
            {permissions.admin.superadmin && hasDevMode && (
              <Fragment>
                <MenuDivider />
                <LinkMenuItem
                  href="/admin/superadmin"
                  text="Super Admin Dashboard"
                  icon={IconNames.PREDICTIVE_ANALYSIS}
                />
              </Fragment>
            )}
          </NavMenu>
        )}

        <NavMenu href="/help" name="help" text="Help">
          <MenuItem
            icon={IconNames.HELP}
            onClick={() => setHelpOpen(true)}
            text="Send Support Request"
          />

          <MenuDivider />

          {!localisation.get('ui.global.websiteLink.disabled') && (
            <MenuItem
              icon={<VGridLogoFavicon height="16" width="16" />}
              onClick={() => window.open('https://vgrid.io', '_blank')}
              text="vGRID Website"
            />
          )}

          <DocsMenuItem />

          <MenuItem
            icon={
              <Icon
                icon={IconNames.OFFLINE}
                color={colors.tree_poppy.DEFAULT}
              />
            }
            onClick={() => window.open(statusPageUrl, '_blank')}
            text="vGRID Status"
          />
        </NavMenu>

        <NavButton
          href="/notifications"
          icon={IconNames.NOTIFICATIONS}
          name="notifications"
        />

        <NavMenu href="/account" icon={IconNames.USER} name="account">
          <li>
            <Link
              className={`${Classes.MENU_ITEM} ${Classes.POPOVER_DISMISS}`}
              tabIndex={-1}
              to="/account"
            >
              <div
                className={`${Classes.TEXT_OVERFLOW_ELLIPSIS} ${Classes.FILL}`}
              >
                <div className={styles.accountName}>{name}</div>
                <div className={styles.accountEmail}>{email}</div>
              </div>
            </Link>
          </li>
          <MenuDivider />
          <LinkMenuItem
            href="/notifications/settings"
            icon={IconNames.NOTIFICATIONS_UPDATED}
            text="Notifications settings"
          />
          {hasFeatures && (
            <Fragment>
              <MenuDivider />
              <LinkMenuItem
                href="/account/beta-features"
                icon={IconNames.BUG}
                text="Beta features"
              />
            </Fragment>
          )}
          {permissions.admin.superadmin && (
            <Fragment>
              <MenuDivider />
              <MenuItem
                icon={IconNames.BUILD}
                text={hasDevMode ? 'Disable Debug' : 'Enable Debug'}
                onClick={() =>
                  hasDevMode ? window.disableDebug() : window.enableDebug()
                }
              />
            </Fragment>
          )}
          <li className={Classes.MENU_HEADER}>
            <h6 className={Classes.HEADING}>Security</h6>
          </li>
          {!currentUser.isSso && (
            <LinkMenuItem
              href="/security/password"
              icon={IconNames.KEY}
              text="Change your password"
            />
          )}
          {localisation.twoStepSettingsShown && !currentUser.isSso && (
            <LinkMenuItem
              href="/security/two-step-verification"
              icon={IconNames.MOBILE_PHONE}
              text="2-step verification"
            />
          )}
          <LinkMenuItem
            href="/security/devices"
            icon={IconNames.CUBE}
            text="Your devices"
          />
          <LinkMenuItem
            href="/security/events"
            icon={IconNames.SHIELD}
            text="Security events"
          />
          <MenuDivider />
          <MenuItem
            icon={IconNames.LOG_OUT}
            onClick={() => logout()}
            text="Logout"
          />
        </NavMenu>

        {hasDevMode && (
          <NavMenu href="/debug" icon={IconNames.CODE_BLOCK} name="debug">
            <div
              className={`${Classes.MENU_ITEM} ${styles.release}`}
              onClick={openSentry}
            >
              <div
                className={`${Classes.TEXT_OVERFLOW_ELLIPSIS} ${Classes.FILL}`}
              >
                <strong>Release</strong>
                <span>{process.env.RELEASE || 'dev'}</span>
                <time>{process.env.BUILD_TIME}</time>
              </div>
            </div>
          </NavMenu>
        )}
      </NavigationProvider>

      {permissions.vault.request && (
        <RequestFiles
          isOpen={vaultRequestFilesOpen}
          onClose={() => setVaultRequestFilesOpen(false)}
        />
      )}
      <HelpDialog isOpen={isHelpOpen} setOpen={setHelpOpen} />
    </div>
  );
};
