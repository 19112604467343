import { Classes, InputGroup } from '@blueprintjs/core';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { maxLength, minLength } from '~/lib/utils/validation';

import { ReferenceInputProps } from '../../../components/pages/anpr/QuickSearch/SearchForm/SearchForm';
import { QuickSearchLocalisation } from '../../localisation';

const infoCallout = (
  <ul className={Classes.LIST_UNSTYLED}>
    <li>
      All search requests are logged and must be used for legitimate Police
      purposes.
    </li>
    <li>Quick Search returns the latest 30 days worth of data.</li>
    <li>
      Refer to the{' '}
      <Link to="/anpr/export" title="Search and Export">
        Search and Export
      </Link>{' '}
      option for additional ANPR data (up to 6 months).
    </li>
  </ul>
);

const InputElement: FC<ReferenceInputProps> = (props) => (
  <InputGroup
    id="reference"
    intent={props.intent}
    name="reference"
    onChange={(event) => props.onChange(event.currentTarget.value)}
    placeholder="Example: P000567890 or 210123/1234"
    value={props.value || ''}
  />
);

const schema = {
  reference: {
    validators: [
      {
        message: 'Police File / Event # must be longer than 3 characters',
        test: minLength(4)
      },
      {
        message: 'Police File / Event # must be shorter than 255 characters',
        test: maxLength(255)
      },
      {
        message: 'Police File / Event # number is required',
        test: true
      }
    ]
  }
};

export const quickSearch: QuickSearchLocalisation = {
  infoCallout,
  reference: {
    helperText: 'Please enter a Police File / Event #',
    InputElement,
    labelText: 'Police File / Event #',
    required: true
  },
  schema
};
