import { Classes } from '@blueprintjs/core';

import { maxLength, minLength } from '../../../lib/utils/validation';
import { SearchAndExportLocalisation } from '../../localisation';

const previewInfo = (
  <ul className={Classes.LIST_UNSTYLED}>
    <li>Fill out the form below to preview search results.</li>
    <li>
      If results are found, you can request approval to view the complete search
      results.
    </li>
    <li>
      Approval should only be requested from someone ranked Senior Sergeant or
      above.
    </li>
    <li>
      Approvers should ensure they are familiar with the current Police ANPR
      Policy.
    </li>
    <li>
      All search requests are logged and must be used for legitimate Police
      purposes.
    </li>
  </ul>
);

const requestInfo = (searchCount: number) => (
  <ul className={Classes.LIST_UNSTYLED}>
    <li>
      We found <strong>{searchCount}</strong> results for your search.
    </li>
    <li>
      Fill out the remaining fields below to request access to the complete
      search results.
    </li>
    <li>
      Police policy requires your request be approved by someone ranked Senior
      Sergeant or above, or by a system administrator.
    </li>
    <li>All search requests are logged.</li>
  </ul>
);

const schema = {
  caseEventId: {
    validators: [
      {
        message: 'Case / Event is a required field',
        test: true
      },
      {
        message: 'Case / Event must be at least 5 characters long',
        test: minLength(5)
      },
      {
        message: 'Please input less than 50 characters',
        test: maxLength(50)
      }
    ]
  },
  description: {
    validators: [
      {
        message: 'Description is a required field',
        test: true
      },
      {
        message: 'Description must be at least 5 characters long',
        test: minLength(5)
      }
    ]
  }
};

export const searchAndExport: SearchAndExportLocalisation = {
  preview: {
    infoCallout: previewInfo
  },
  request: {
    approverCheckboxLabel:
      'I approve my own request. My rank is Senior Sergeant or above, or I am a system administrator.',
    caseEventId: {
      labelText: 'Case / Event',
      placeholder: 'Examples: P000567890, 210123/1234, OP Handle, Live event'
    },
    description: {
      placeholder: [
        'Examples:',
        '  - Vehicle used in homicide enquiry - Cremorne St, Herne Bay',
        '  - Occupants of vehicle involved in Assault on Bayview Terr',
        '  - Vehicle FTS on Heathfield Ave, Fendalton'
      ].join('\n'),
      required: true
    },
    infoCallout: requestInfo,
    schema
  }
};
